.sidedrawer {
  width: 70%;
  background-color: #181818;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  transform: translateX(-100%);
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidedrawer.show {
  transform: translateX(0);
}

.sidedrawer__links {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.sidedrawer__links > li {
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
}

.sidedrawer__links > li > a:hover {
  background: #21c7dd;
  color: #171717;
}

.sidedrawer__links > li > a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  padding: 1rem;
  text-decoration: none;
  color: #f4f4f4;
  font-size: 1.6rem;
}

.sidedrawer__links > li > a > span {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.sidedrawer__cartbadge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #f4f4f4;
  border-radius: 50%;
  margin-left: 10px;
  color: #171717;
  font-size: 1rem;
}

.sidedrawer__links > li > a:hover .sidedrawer__cartbadge {
  background-color: #171717;
  color: #ffff;
}

@media (min-width: 960px) {
  .sidedrawer {
    display: none;
  }
}
