.backdrop {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 960px) {
  .backdrop {
    display: none;
  }
}
