.cartitem {
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr 1fr 1fr;
  gap: 8px;
  color: #f4f4f4;
  background: #171717;
  border-radius: 10px;
  place-items: center;
  margin-bottom: 8px;
}

.cartItem__name {
  text-decoration: none;
  color: #f4f4f4;
}

.cartItem__name:hover {
  color: #21c7dd;
}

.cartItem__select {
  padding: 10px 17px;
  border-radius: 5px;
}

.cartItem__deleteBtn {
  padding: 10px 17px;
  color: red;
  background: none;
  border: 1px solid #171717;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.cartItem__deleteBtn:hover,
.carItem__deleteBtn:active,
.carItem__deleteBtn:focus {
  transform: scale(1.5);
}

@media (max-width: 700px) {
  .cartItem__name {
  }

  .cartItem__select,
  .cartItem__deleteBtn {
    padding: 8px 13px;
  }

  .cartitem__price {
    font-size: 0.8rem;
  }
}

@media (max-width: 700px) {
  .cartItem__name {
    font-size: 0.8rem;
  }

  .cartItem__select,
  .cartItem__deleteBtn {
    padding: 5px 8px;
  }

  .cartitem__price {
    font-size: 0.8rem;
  }
}
