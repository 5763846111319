* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  background: #f6f6f6;
}

img {
  width: 100%;
  object-fit: contain;
}
