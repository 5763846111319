.navbar {
  width: 100%;
  background-color: #181818;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem;
  z-index: 50;
}

.navbar__logo h2 {
  color: #f4f4f4;
  font-size: 1.4rem;
  cursor: pointer;
}

.navbar__links {
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar__links > li {
  padding: 0rem 1rem;
}

.navbar__links > li > a {
  text-decoration: none;
  color: #f4f4f4;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.navbar__links > li > a > span {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.cart__link {
  background: rgb(134, 132, 132);
  padding: 10px;
  border-radius: 8px;
}
.home_link {
  padding: 15px;
  border-radius: 8px;
}

.cart__link:hover {
  background: #21c7dd;
  color: #f4f4f4;
}
.home_link:hover {
  background: #21c7dd;
  color: #f4f4f4;
}

.cartlogo__badge {
  width: 30px;
  height: 30px;
  background-color: #f4f4f4;
  border-radius: 50%;
  margin-left: 8px;
  color: #171717;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.hamburger__menu {
  display: none;
  width: 30px;
  height: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.hamburger__menu:hover > div {
  background: #21c7dd;
}

.hamburger__menu > div {
  width: 100%;
  height: 3px;
  background: #f4f4f4;
}

@media (max-width: 960px) {
  .navbar__links {
    display: none;
  }

  .hamburger__menu {
    display: flex;
  }
}

@media (max-width: 500px) {
  .navbar__logo h2 {
    font-size: 1rem;
  }
}
